import './App.css';
import Pages from './pages/index';
import Terms from './pages/terms/terms';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' Component={Pages}></Route>
          <Route path='/terms' Component={Terms}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
