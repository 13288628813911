import { useLocation } from "react-router-dom";
import Bpm from "../components/Bpm/Bpm";
import ConsultoriaIntegral from "../components/Consultor/Consultor";
import ContactSection from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/Hero";
import Separator from "../components/Separator/Separator";
import Services from "../components/Services/Services";
import StaffAugmentation from "../components/StaffAugmentation/StaffAugmentation";
import ValueProposition from "../components/ValueProposition/ValueProposition";
import { useEffect, useRef } from "react";

export default function Home() {
  const location = useLocation();
  const lastHash = useRef('');
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return (
    <div className="flex flex-col">
      <Hero/>
      <Separator/>
      <ValueProposition />
      <Separator/>
      <Services/>
      <ConsultoriaIntegral />
      <Bpm />
      <StaffAugmentation />
      <Separator />
      <ContactSection />
      <Separator />
      <Footer />
    </div>
  );
}
