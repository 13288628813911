import { Link } from "react-router-dom";

interface ContactInfoProps {
  imageSrc: string;
  title: string;
  description: string;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ imageSrc, title, description }) => (
  <div className="flex flex-col items-center text-base leading-6 text-center text-white max-md:mt-8">
    <img src={imageSrc} alt="" className="w-12" />
    <div className="justify-center self-stretch mt-5">{title}</div>
    <div className="justify-center mt-5 font-semibold">{description}</div>
  </div>
);

const Footer: React.FC = () => {
  const contactInfoData = [
    {
      imageSrc: "/assets/mail.svg",
      title: "Comuninicate con nuestro equipo",
      description: "contact@staffingexperience.com",
    },
    {
      imageSrc: "/assets/location.svg",
      title: "Contactanos",
      description: "Miami, Florida, FL 33182 1057 NW 136th Ave",
    }
  ];

  const socialMediaIcons = [
    { link: 'https://www.instagram.com/staffing.experience/', src: "/assets/instagram.svg", alt: "Social media icon 1" },
    { link: 'http://linkedin.com/company/staffing-experience', src: "/assets/linkeding.svg", alt: "Social media icon 2" },
  ];

  return (
    <footer className="flex flex-col px-20 pt-14 pb-12 w-full bg-stone-900 max-md:px-5 max-md:max-w-full items-center">
      <div className="flex flex-col px-8 pb-6 text-base font-semibold leading-6 text-gray-200 whitespace-nowrap max-md:px-5 max-md:max-w-full max-w-screen-xl items-center size-full">
        <div className="flex flex-col max-md:max-w-full">
          <img
            loading="lazy"
            src="/assets/logo.svg"
            alt="Company logo"
            className="self-center max-w-full w-[250px]"
          />
          <nav className="flex justify-center items-center px-16 mt-8 max-md:px-5 max-md:max-w-full">
            <ul className="flex  flex-wrap gap-5 justify-between">
              <li className="flex flex-col justify-center">
                <a className="justify-center" href="/#init">Inicio</a>
              </li>
              <li className="flex flex-col justify-center">
                <a className="justify-center" href='/#services'>Servicios</a>
              </li>
              <li className="flex flex-col justify-center">
                <a className="justify-center" href="/#ours">Nosotros</a>
              </li>
              <li className="flex flex-col justify-center">
                <a className="justify-center" href="/#contact">Contactanos</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="flex flex-col justify-center px-8 mt-10 max-md:px-5 max-md:max-w-full max-w-screen-xl items-center">
        <div className="max-md:max-w-full">
          <div className="flex justify-between max-md:flex-col max-md:gap-0">
            {contactInfoData.map((info, index) => (
              <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <ContactInfo
                  imageSrc={info.imageSrc}
                  title={info.title}
                  description={info.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center px-8 mt-10 max-md:px-5 max-md:max-w-full max-w-screen-xl size-full">
        <div className="flex gap-5 items-start pt-8 border-t border-solid border-slate-600 max-md:flex-wrap max-sm:flex-col max-sm:items-center">
          <div className="flex-1 self-stretch text-base leading-6 text-white max-md:max-w-full">
            © 2024 Staffing Experience. All rights reserved.
          </div>
          <div className="justify-center text-base leading-6 text-white">
           <Link to="/terms">Politica de privacidad</Link>
          </div>
          <div className="flex gap-5 justify-between">
            {socialMediaIcons.map((icon, index) => (
              <a href={icon.link} key={index}>
                <img
                  loading="lazy"
                  src={icon.src}
                  alt={icon.alt}
                  className="shrink-0 w-6 aspect-square"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;