import * as React from "react";

interface HeroSectionProps {
  title: string;
  subtitle: string;
  description: string;
  imageUrl: string;
  imageAlt: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subtitle,
  description,
  imageUrl,
  imageAlt,
}) => {
  return (
    <section className="flex justify-center items-center px-16 pt-20 pb-20 w-full bg-white max-md:px-5 max-md:max-w-full">
      <div className="justify-center px-8 mt-4 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col font-semibold text-gray-900 max-md:max-w-full">
                <h1 className="flex justify-start text-4xl tracking-tighter leading-10 max-md:max-w-full">
                  {title}
                </h1>
                <h2 className="flex justify-start mt-3 text-2xl tracking-tight leading-10 max-md:max-w-full">
                  {subtitle}
                </h2>
              </div>
              <div className="flex flex-col justify-start text-justify mt-10 text-lg leading-7 text-slate-600 max-md:max-w-full">
                <p className="text-justify pb-5 max-md:max-w-full">Imagina un mundo donde tus procesos empresariales funcionan como un reloj suizo. Con nuestra solución BPM, podrás optimizar cada paso de tu operación, desde la identificación hasta la mejora continua. Obtén visibilidad completa sobre tus operaciones, automatiza tareas clave y mejora la colaboración entre equipos. Descubre cómo puedes transformar tu empresa en una máquina de eficiencia y resultados.</p>
                <p>Nuestro enfoque se basa en:<br/></p>
                <p className="indent-5">&#x2022; Identificar, modelar y optimizar procesos empresariales.</p>
                <p className="indent-5">&#x2022; Ofrecer visibilidad y control sobre las operaciones.<br/></p>
                <p className="indent-5">&#x2022; Facilitar la automatización y el seguimiento de tareas.<br/></p>
                <p className="indent-5">&#x2022; Mejorar la coordinación y la colaboración entre equipos.<br/></p>
                <p className="indent-5">&#x2022; Impulsar la agilidad y la adaptabilidad empresarial.</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full items-center">
            <img
              loading="lazy"
              src={imageUrl}
              alt={imageAlt}
              style={{ objectPosition: '40%', height: '36rem', width: '36rem'}}
              className="mt-1.5 w-full max-md:mt-10 max-md:max-w-full object-cover rounded-md"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

function MyComponent() {
  return (
    <HeroSection
      title="BPM"
      subtitle="(Gestión de Procesos de Negocio)"
      description={``}
      imageUrl="/assets/bpm.webp"
      imageAlt="BPM (Gestión de Procesos de Negocio)"
    />
  );
}

export default MyComponent;