import * as React from "react";


const ConsultoriaIntegral: React.FC = () => {
  return (
    <section className="flex justify-center items-center px-16 py-20 w-full bg-white max-md:px-5 max-md:max-w-full">
      <div className="justify-center px-8 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto text-4xl font-semibold tracking-tighter leading-10 text-black max-md:mt-10 max-md:max-w-full items-center">
              <h2 className="flex justify-start bg-white max-md:max-w-full">Consultoria Integral</h2>
              <img src='/assets/rpa_1.webp' alt='rpa' 
              style={{ objectPosition: '50% 0%', height: '34rem', width: '38rem'}}
              className="mt-16 w-full max-md:mt-10 max-md:max-w-full object-cover rounded-md" />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow justify-center pt-20 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-0 mt-20 font-semibold text-gray-900 max-md:flex-wrap max-md:mt-10">
                <h3 className="text-4xl tracking-tighter leading-10">RPA</h3>
                <span className="flex-1 text-2xl tracking-tight leading-10 max-md:max-w-full">
                  {" "}
                  (Automatización de Procesos Robóticos)
                </span>
              </div>
              <div className="flex flex-col mt-2.5 text-lg leading-7 text-slate-600 max-md:max-w-full">
                <div className="text-justify pb-5 max-md:max-w-full">
                  <p>En Staffing Experience, transformamos tus operaciones con la magia de la automatización. Liberamos a
                  tu equipo de tareas repetitivas y operativas para que puedas concentrarte en lo que realmente importa;
                  agregar valor y fomentar la toma de decisiones estratégicas. Nuestra experiencia y expertise en el
                  Modelo de Gobierno del ecosistema de automatización nos permite identificar las palancas de valor que
                  benefician tu línea de negocio.
                  <br />
                  Te acompañamos para que tus procesos sean más eficientes y precisos, ahorrando tiempo y recursos, y
                  minimizando los riesgos financieros del negocio. ¿Estás listo para simplificar tu día a día y alcanzar
                  nuevos niveles de productividad? Descubre cómo podemos ayudarte.</p>
                  <br />
                  Nuestro enfoque se fundamenta en:
                  <br />
                  <div style={{textIndent: '1.25rem'}} className="flex flex-col justify-start text-justify text-lg leading-7 max-md:max-w-full">
                    <p>&#x2022; Automatizar tareas repetitivas y manuales.</p>
                    <p>&#x2022; Mejorar la eficiencia operativa y la precisión.</p>
                    <p>&#x2022; Liberar a los empleados para actividades de mayor valor.</p>
                    <p>&#x2022; Agilizar procesos y reducir los tiempos de ejecución.</p>
                    <p className="text-start">&#x2022; Aumentar la productividad mediante la óptima utilización de recursos.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultoriaIntegral;