import Header from "../../components/Header/Header";
import HeroTerm from "../../components/Terms/TermsHero";
import Footer from "../../components/Footer/Footer";
import Content from "../../components/Terms/Content";
import { useEffect } from "react";

export default function Page() {
  useEffect(()=> {
    window.scrollTo(0,0)
  },[])
  return (
    <div className="flex flex-col" id="initTerm">
      <Header/>
      <HeroTerm/>
      <Content/>
      <Footer/>
    </div>
  );
}