import ContactSection from "../ContactModal/Contact";

type ModalProps = {
    modal: boolean;
    onCloseModal: () => void;
}

const Modal = ({ modal, onCloseModal }: ModalProps) => {
    if (!modal) {
      return null;
    }
    return (
      <div className="flex absolute top-0 right-0 p-8 w-full min-h-screen" style={{ zIndex: 2,height: 'calc(100vh *2)',backgroundColor: 'rgba(0, 0, 0, 0.4)' }} onClick={onCloseModal} >
        <div className="relative h-px-300 overflow-hidden rounded-md p-10 " style={{ zIndex: 3}}>
          <section className="flex justify-center rounded-md items-center px-16 w-full bg-white max-md:px-5 max-md:max-w-full"onClick={e => { e.stopPropagation() }}>
              <div className="flex gap-5 max-md:flex-col max-md:gap-0" id="contact">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full max-md:hidden">
                  <img
                    loading="lazy"
                    src="/assets/contact.webp"
                    alt="Contact us"
                    className="self-stretch my-auto w-full aspect-[0.75] max-md:mt-10 max-md:max-w-full rounded-md"
                  />
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center self-stretch px-12 py-20 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col mt-14 max-md:mt-10 max-md:max-w-full">
                      <div className="flex flex-col pr-8 max-md:pr-5 max-md:max-w-full">
                        <h2 className="text-4xl font-semibold tracking-tighter leading-10 text-neutral-500 max-md:max-w-full">
                          Contactanos
                        </h2>
                        <p className="mt-5 text-xl leading-8 text-slate-600 max-md:max-w-full">
                          Nuestro equipo esta esperando por ti.
                        </p>
                      </div>
                      <ContactSection closeModal={onCloseModal}/>
                    </div>
                  </div>
                </div>
              </div>
          </section>
        </div>
      </div>
    );
  };
  
  export default Modal;
  