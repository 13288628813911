import { Link } from "react-router-dom";
import * as React from "react";

interface InputProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: (data:any) => void;
  error: string;
  title: string;
}

const Input: React.FC<InputProps> = ({ label, placeholder, onChange, value, error, title }) => (
  <div className="flex flex-col justify-center flex-wrap">
    <label htmlFor={label} className="flex justify-start text-sm font-medium leading-5 text-slate-700">
      {title}
    </label>
    <input
      type="text"
      id={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className="flex flex-col justify-center px-3.5 py-2.5 mt-1.5 text-base leading-6 text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm"
    />
    {error && (
          <p className="text-red-600">{error}</p>
        )}
  </div>
);
type PhoneInputProps = {
  onChange: (data:any) => void;
  value: string;
  error: string;
}
const PhoneInput: React.FC<PhoneInputProps> = ({ onChange, value, error }) => (
  <div className="flex flex-col justify-center mt-6 max-md:max-w-full">
    <label htmlFor="phone" className="flex justify-start text-sm font-medium leading-5 text-slate-700 max-md:max-w-full">
      Numero de telefono
    </label>
    <div className="flex gap-0 mt-1.5 text-base leading-6 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:flex-wrap">
      {/* <div className="flex gap-0 justify-between py-2.5 pl-3.5 text-gray-900 whitespace-nowrap">
        <div>AR</div>
        <img loading="lazy" src="" alt="Argentina flag" className="shrink-0 my-auto w-5 aspect-square" />
      </div> */}
      <input
        type="tel"
        id="phone"
        value={value}
        onChange={onChange}
        placeholder="+1 (011) 0000-0000"
        className="flex-1 justify-center py-2.5 pr-3.5 pl-3 text-gray-500 max-md:max-w-full rounded-lg"
      />
    </div>
      {error && (
          <p className="text-red-600">{error}</p>
        )}
  </div>
);

type CheckboxProps = {
  onChange: (data:any) => void;
  value: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({onChange, value}) => (
  <div className="flex gap-3 mt-6 max-md:flex-wrap">
    <div className="flex flex-col justify-center self-start pt-0.5 pb-px">
      <input type="checkbox" id="policy"  checked={value} onChange={onChange} className="shrink-0 w-5 h-5 bg-white rounded-md border border-gray-300 border-solid" />
    </div>
    <span className="flex justify-start gap-1 text-base leading-6 text-slate-600 max-md:max-w-full">
      Acepto las <Link to="/terms" className="underline">politicas de privacidad.</Link>
    </span>
  </div>
);

type ErrorsProps = {
  name: string, surname:string, message: string, policy: string, phone:string, email:string, files: string
} 

type ContactFormProps = {
  onCloseModal : ()=> void;
}
const ContactForm: React.FC<ContactFormProps> = ({onCloseModal}) => {
  const [inputsVal, setInputsVal] = React.useState({ name: '', surname:'', message: '', policy: false, phone:'', email:'', files: { fileName: '', fileContent: '' }, errors:{} as ErrorsProps });
  const [fileError, setFileError] = React.useState('');

  const handleChange = (event:any) : void => {
    const { id, value, checked } = event.target;
    setInputsVal((prevFormData)=> ({...prevFormData, [id]:(id !== 'policy') ? value : checked }));
  }

  const handleFilesSelected = (files: FileProps) => {
    setInputsVal((preventDefault) => ({...preventDefault, files}))
  };

  const validateForm = ()=> {
    let errors = {} as ErrorsProps;
    if(!inputsVal.name) errors.name = 'Campo nombre es requerido';
    if(!inputsVal.surname) errors.surname = 'Campo apellido es requerido';
    if(!inputsVal.message) errors.message = 'Campo mensaje es requerido';
    if(!inputsVal.phone) errors.phone = 'Campo numero de telefono es requerido';
    if(!inputsVal.policy) errors.policy = 'Campo politica es requerido';
    if(!inputsVal.email) errors.email = 'Campo email es requerido';
    // eslint-disable-next-line no-useless-escape
    if(inputsVal.email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputsVal.email)) errors.email = 'Email formato invalido';
    if(!inputsVal.files.fileContent) errors.files = 'Campo CV es requerido';
    if(fileError) errors.files = fileError;
    setInputsVal((preventDefault)=>({...preventDefault, errors}))
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async (event:any) => {
    event.preventDefault();
    if(validateForm()) {
      try {
          const {name, email, message, phone, surname, files} = inputsVal;
          
          await fetch("https://" + process.env.REACT_APP_API_SES || '', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Api-Key': process.env.REACT_APP_API_HOST ?? '' 
            },
            body: JSON.stringify({name, email, message,phone,surname, fileContent: files.fileContent, fileName: files.fileName, typeSite: 'employee'})
          });

            alert('Se envio con exito');
            setInputsVal({ name: '', surname:'', message: '', policy: false, phone:'', email:'', files: { fileContent:'', fileName: ''}, errors: {} as ErrorsProps });

      } catch (error) {
        console.error(error); 
        alert('Ocurrio un error en el envio'); 
      }
      onCloseModal();
    }
  }

  return (
    <form className="flex flex-col mt-3 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 whitespace-nowrap max-lg:flex-wrap">
        <Input label="name" title='Nombre' placeholder="Nombre" onChange={handleChange} value={inputsVal.name} error={inputsVal.errors.name} />
        <Input label="surname" title='Apellido' placeholder="Apellido" onChange={handleChange} value={inputsVal.surname} error={inputsVal.errors.surname}/>
      </div>
      <div className="flex flex-col justify-center mt-6 whitespace-nowrap max-md:max-w-full">
        <label htmlFor="email" className="flex justify-start text-sm font-medium leading-5 text-slate-700 max-md:max-w-full">
          Email
        </label>
        <input
          type="email"
          id="email"
          onChange={handleChange}
          value={inputsVal.email}
          placeholder="tu-email@company.com"
          className="flex flex-col justify-center px-3.5 py-2.5 mt-1.5 text-base leading-6 text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
        />
        {inputsVal.errors.email && (
          <p className="text-red-600">{inputsVal.errors.email}</p>
        )}
      </div>
      <PhoneInput onChange={handleChange} value={inputsVal.phone} error={inputsVal.errors.phone}/>
      
      <div className="flex flex-col justify-center mt-6 max-md:max-w-full">
        <label htmlFor="message" className="flex justify-start text-sm font-medium leading-5 text-slate-700 max-md:max-w-full">
          Mensaje
        </label>
        <textarea
          id="message"
          placeholder="Dejanos tu mensaje..."
          onChange={handleChange}
          value={inputsVal.message}
          className="justify-center px-3.5 py-3 mt-1.5 text-base leading-6 text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
        ></textarea>
        {inputsVal.errors.message && (
          <p className="text-red-600">{inputsVal.errors.message}</p>
        )}
      </div>
      <FileInput onFilesSelected={handleFilesSelected} onError={(error) => setFileError(error)}/>
      {inputsVal.errors.files && (
          <p className="text-red-600">{inputsVal.errors.files}</p>
        )}
      <Checkbox onChange={handleChange} value={inputsVal.policy}/>
      {inputsVal.errors.policy && (
          <p className="text-red-600">{inputsVal.errors.policy}</p>
        )}
      <button
        type="submit"
        onClick={handleSubmit}
        className="flex flex-col  mt-8 text-base font-semibold leading-6 text-white whitespace-nowrap max-md:max-w-full justify-center items-center px-5 py-3 rounded-lg border border-solid shadow-sm bg-stone-300 border-neutral-400"
      >
        Enviar
      </button>
    </form>
  );
}
type FileProps ={ fileName:string, fileContent:string };
interface FileInputProps {
  onFilesSelected: (file: FileProps) => void;
  onError: (error: string) => void;
}

const FileInput: React.FC<FileInputProps> = ({ onFilesSelected, onError }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files?.length)?event.target.files[0] : null;
    const reader = new FileReader();
    reader.onloadend = ()=> {
      const base64String = reader.result as string;
      onFilesSelected({fileContent: base64String, fileName: file?.name ?? 'cv.pdf'});
    }
    if (file) {
      const size = Math.round((file.size / 1024));
      if(size <= 2048) {
        onError('');
        reader.readAsDataURL(file);
      } else {
        onError('Archivo cargado debe de ser inferior a 5mb')
      }
    }
  };

  return (
    <div className="flex flex-col justify-center mt-6 max-md:max-w-full">
      <label htmlFor="file" className="flex justify-start text-sm font-medium leading-5 text-slate-700 max-md:max-w-full">
        Adjuntar archivo CV
      </label>
      <div className="flex items-center justify-center w-full">
        <label
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className="w-10 h-10 mb-3 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Haz click para cargar el CV</span> o deslizalo hasta acá
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              .pdf (MAX. 2MB)
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            multiple
            accept=".pdf"
            onChange={handleFileChange}
          />
        </label>
      </div>
    </div>
  );
};
type ContactSectionProps = {
  closeModal: ()=> void;
}
const ContactSection: React.FC<ContactSectionProps> = ({closeModal}: ContactSectionProps) => (
  <>
  <div className="text-black">
  <h2 className="text-2xl pb-6 font-semibold leading-6 text-neutral-500 max-md:max-w-full max-sm:text-xl">Unete a nuestro equipo</h2>
  </div>
   <ContactForm onCloseModal={closeModal}/>
  </>
);

export default ContactSection;