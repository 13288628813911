"use client"

import { Link } from "react-router-dom";
import * as React from "react";

interface InputProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: (data:any) => void;
  error: string;
  title: string;
}

const Input: React.FC<InputProps> = ({ label, placeholder, onChange, value, error, title }) => (
  <div className="flex flex-col justify-center">
    <label htmlFor={label} className="flex justify-start text-sm font-medium leading-5 text-slate-700">
      {title}
    </label>
    <input
      type="text"
      id={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className="flex flex-col justify-center px-3.5 py-2.5 mt-1.5 text-base leading-6 text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm"
    />
    {error && (
          <p className="text-red-600">{error}</p>
        )}
  </div>
);
type PhoneInputProps = {
  onChange: (data:any) => void;
  value: string;
  error: string;
}
const PhoneInput: React.FC<PhoneInputProps> = ({ onChange, value, error }) => (
  <div className="flex flex-col justify-center mt-6 max-md:max-w-full">
    <label htmlFor="phone" className="flex justify-start text-sm font-medium leading-5 text-slate-700 max-md:max-w-full">
      Numero de telefono
    </label>
    <div className="flex gap-0 mt-1.5 text-base leading-6 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:flex-wrap">
      {/* <div className="flex gap-0 justify-between py-2.5 pl-3.5 text-gray-900 whitespace-nowrap">
        <div>AR</div>
        <img loading="lazy" src="" alt="Argentina flag" className="shrink-0 my-auto w-5 aspect-square" />
      </div> */}
      <input
        type="tel"
        id="phone"
        value={value}
        onChange={onChange}
        placeholder="+1 (011) 0000-0000"
        className="flex-1 justify-center py-2.5 pr-3.5 pl-3 text-gray-500 max-md:max-w-full rounded-lg"
      />
    </div>
      {error && (
          <p className="text-red-600">{error}</p>
        )}
  </div>
);

type CheckboxProps = {
  onChange: (data:any) => void;
  value: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({onChange, value}) => (
  <div className="flex gap-3 mt-6 max-md:flex-wrap">
    <div className="flex flex-col justify-center self-start pt-0.5 pb-px">
      <input type="checkbox" id="policy"  checked={value} onChange={onChange} className="shrink-0 w-5 h-5 bg-white rounded-md border border-gray-300 border-solid" />
    </div>
    <span className="flex justify-start gap-1  text-base leading-6 text-slate-600 max-md:max-w-full">
      Acepto las <Link to="/terms" className="underline">politicas de privacidad.</Link>
    </span>
  </div>
);

type ErrorsProps = {
  name: string, surname:string, message: string, policy: string, phone:string, email:string
} 

const ContactForm: React.FC = () => {
  const [inputsVal, setInputsVal] = React.useState({ name: '', surname:'', message: '', policy: false, phone:'', email:'', errors:{} as ErrorsProps });

  const handleChange = (event:any) : void => {
    const { id, value, checked } = event.target;
    setInputsVal((prevFormData)=> ({...prevFormData, [id]:(id !== 'policy') ? value : checked }));
  }

  const validateForm = ()=> {
    let errors = {} as ErrorsProps;
    if(!inputsVal.name) errors.name = 'Campo nombre es requerido';
    if(!inputsVal.surname) errors.surname = 'Campo apellido es requerido';
    if(!inputsVal.message) errors.message = 'Campo mensaje es requerido';
    if(!inputsVal.phone) errors.phone = 'Campo numero de telefono es requerido';
    if(!inputsVal.policy) errors.policy = 'Campo politica es requerido';
    if(!inputsVal.email) errors.email = 'Campo email es requerido';
    // eslint-disable-next-line no-useless-escape
    if(inputsVal.email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputsVal.email)) errors.email = 'Email formato invalido';

    setInputsVal((preventDefault)=>({...preventDefault, errors}))
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async (event:any) => {
    event.preventDefault();
    if(validateForm()) {
      try {
        const {name, email, message, phone, surname} = inputsVal;
        
        await fetch("https://" + process.env.REACT_APP_API_SES || '', {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Api-Key': process.env.REACT_APP_API_HOST ?? '',
          },
          body: JSON.stringify({name, message, email, phone,surname, typeSite: 'client'})
        })
        
          alert('Se envio con exito');
          setInputsVal({ name: '', surname:'', message: '', policy: false, phone:'', email:'', errors: {} as ErrorsProps });
            
      } catch (error) {
        console.error(error); 
        alert('Ocurrio un error en el envio');       
      }
    }
  }

  return (
    <form className="flex flex-col mt-12 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 flex-wrap overflow-hidden">
        <Input label="name" title='Nombre' placeholder="Nombre" onChange={handleChange} value={inputsVal.name} error={inputsVal.errors.name} />
        
        <Input label="surname" title='Apellido' placeholder="Apellido" onChange={handleChange} value={inputsVal.surname} error={inputsVal.errors.surname}/>
      </div>
      <div className="flex flex-col justify-center mt-6 whitespace-nowrap max-md:max-w-full">
        <label htmlFor="email" className="flex justify-start text-sm font-medium leading-5 text-slate-700 max-md:max-w-full">
          Email
        </label>
        <input
          type="email"
          id="email"
          onChange={handleChange}
          value={inputsVal.email}
          placeholder="tu-email@company.com"
          className="flex flex-col justify-center px-3.5 py-2.5 mt-1.5 text-base leading-6 text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
        />
        {inputsVal.errors.email && (
          <p className="text-red-600">{inputsVal.errors.email}</p>
        )}
      </div>
      <PhoneInput onChange={handleChange} value={inputsVal.phone} error={inputsVal.errors.phone}/>
      
      <div className="flex flex-col justify-center mt-6 max-md:max-w-full">
        <label htmlFor="message" className="flex justify-start text-sm font-medium leading-5 text-slate-700 max-md:max-w-full">
          Mensaje
        </label>
        <textarea
          id="message"
          placeholder="Dejanos tu mensaje..."
          onChange={handleChange}
          value={inputsVal.message}
          className="justify-center px-3.5 py-3 mt-1.5 text-base leading-6 text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
        ></textarea>
        {inputsVal.errors.message && (
          <p className="text-red-600">{inputsVal.errors.message}</p>
        )}
      </div>
      <Checkbox onChange={handleChange} value={inputsVal.policy}/>
      {inputsVal.errors.policy && (
          <p className="text-red-600">{inputsVal.errors.policy}</p>
        )}
      <button
        type="submit"
        onClick={handleSubmit}
        className="flex flex-col  mt-8 text-base font-semibold leading-6 text-white whitespace-nowrap max-md:max-w-full justify-center items-center px-5 py-3 rounded-lg border border-solid shadow-sm bg-stone-300 border-neutral-400"
      >
        Enviar
      </button>
    </form>
  );
} 

const ContactSection: React.FC = () => (
  <section className="flex justify-center items-center px-16 w-full bg-white max-md:px-5 max-md:max-w-full ">
    <div className="justify-center px-8 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0" id="contact">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full max-sm:hidden">
          <img
            loading="lazy"
            src="/assets/contact.webp"
            alt="Contact us"
            className="self-stretch my-auto w-full aspect-[0.75] max-md:mt-10 max-md:max-w-full rounded-md"
          />
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow justify-center self-stretch px-12 py-20 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col mt-14 max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col pr-8 max-md:pr-5 max-md:max-w-full">
                <h2 className="flex justify-start text-4xl font-semibold tracking-tighter leading-10 text-neutral-500 max-md:max-w-full">
                  Contactanos
                </h2>
                <p className="flex justify-start mt-5 text-xl leading-8 text-slate-600 max-md:max-w-full">
                  Nuestro equipo esta esperando por ti.
                </p>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ContactSection;