import * as React from "react";

function ValueProposition() {
  return (
    <section className="flex flex-col items-center justify-center py-14 w-full bg-white max-md:max-w-full" id="ours" >
      <div className="flex flex-col justify-center self-center px-8 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full ">
        <div className="flex flex-col max-md:max-w-full max-w-screen-xl" >
          <h2 className="flex justify-start text-4xl max-sm:text-3xl font-semibold leading-6 text-neutral-500 max-md:max-w-full  max-sm:max-w-sm">
            Nuestra propuesta de Valor
          </h2>
          <div className="flex flex-col justify-center mt-10 text-lg leading-7 text-slate-600 max-md:max-w-full">
            <p className="text-justify justify-center max-md:max-w-full">
              En Staffing Experience, nos comprometemos a ofrecer soluciones integrales que impulsen el crecimiento y el éxito de nuestros clientes. Nos destacamos por nuestra capacidad para identificar oportunidades de mejora en los procesos de negocio, aprovechando nuestra amplia experiencia en procesos empresariales, proyectos de IT y mejora continua.
              <br />
              Lo que nos distingue es nuestra capacidad para proporcionar soluciones personalizadas que abordan tanto las necesidades de recursos humanos como los desafíos operativos y tecnológicos que enfrentan nuestros clientes. Al optar por nuestros servicios, no solo están contratando personal altamente calificado, sino que también están estableciendo alianzas estratégicas con un equipo que entiende profundamente sus necesidades y desafíos únicos.
              <br />
              Nuestra promesa es impulsar la eficiencia, la innovación y el éxito en un mercado competitivo y en constante evolución. En Staffing Experience, somos su socio confiable en el camino hacia el crecimiento y la excelencia empresarial.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center px-3 mt-12 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full max-w-screen-xl">
        <div className="flex flex-col justify-center mx-5 max-md:px-5 max-md:mr-2.5 max-md:max-w-full">
        <img loading="lazy" src={'/assets/consult-6.webp'} alt={'valueProposition'} 
        style={{ objectPosition: '0% 65%', height: '30rem', width: '80rem'}}
        className="w-full max-md:max-w-full object-cover rounded-md" />
        </div>
      </div>
    </section>
  );
}

export default ValueProposition;