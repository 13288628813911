import * as React from "react";

function Separator() {
  return (
    <div className="flex justify-center items-center px-16 w-full bg-white max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col justify-center px-8 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full">
        <div className="shrink-0 h-px bg-gray-200 max-md:max-w-full" />
      </div>
    </div>
  );
}

export default Separator;
