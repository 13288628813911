import * as React from "react";

interface LastUpdatedProps {
  date: string;
}

const LastUpdated: React.FC<LastUpdatedProps> = ({ date }) => (
  <div className="text-base leading-6 text-gray-200 max-md:max-w-full">
    Ultima actualización {date}
  </div>
);

interface HeadingProps {
  text: string;
}

const Heading: React.FC<HeadingProps> = ({ text }) => (
  <h1 className="mt-3 text-5xl tracking-tighter text-white max-md:max-w-full max-md:text-4xl">
    {text}
  </h1>
);

interface DescriptionProps {
  text: string;
}

const Description: React.FC<DescriptionProps> = ({ text }) => (
  <p className="self-center mt-6 text-xl leading-8 text-gray-200 w-[768px] max-md:max-w-full">
    {text}
  </p>
);

function TermsAndConditions() {
  return (
    <section className="flex justify-center items-center self-stretch px-16 py-20 text-center bg-stone-900 max-md:px-5">
      <div className="flex flex-col justify-center px-8 mt-4 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full">
        <div className="flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col max-w-full w-[960px]">
            <div className="flex flex-col font-semibold max-md:max-w-full">
              <LastUpdated date="28 Mar 2024" />
              <Heading text="Términos condiciones" />
            </div>
            <Description text="Al acceder a nuestro sitio web, usted acepta estar sujeto a estos términos de servicio, todas las leyes y regulaciones aplicables, y acepta que es responsable del cumplimiento de las leyes locales aplicables." />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditions;