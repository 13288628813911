import { Link } from "react-router-dom";

interface LogoProps {
    src: string;
    alt: string;
  }
  
  const Logo: React.FC<LogoProps> = ({ src, alt }) => (
        <div className="flex justify-center items-center self-stretch my-auto max-md:mt-7 overflow-hidden">
            <img loading="lazy" src={src} alt={alt} className="w-[300px]" />
        </div>
  );
  
  interface NavItemProps {
    title: string;
    scrollTo: string;
  }
  
  const NavItem: React.FC<NavItemProps> = ({ title, scrollTo }) => (
    <Link to={scrollTo}>
        <div className="justify-center self-stretch my-auto">{title}</div>
    </Link>
  );

  interface NavDropdownProps {
    label: string;
    iconSrc: string;
    iconAlt: string;
    scrollTo: string;
  }
  
const NavDropdown: React.FC<NavDropdownProps> = ({ label, iconSrc, iconAlt, scrollTo }) => (
  <Link to={scrollTo}>
    <div className="flex flex-col justify-center self-stretch py-1">
      <div className="flex gap-2 justify-center">
        <div>{label}</div>
        {/* <img loading="lazy" src={iconSrc} alt={iconAlt} className="shrink-0 my-auto w-5 aspect-square" /> */}
      </div>
    </div>
  </Link>
);

function Header() {
    return (    
    <header className="flex flex-col justify-center w-full border-solid bg-stone-900 dark:bg-stone-900 border-b-[13px] border-neutral-500 max-md:max-w-full items-center">
      <div className="flex flex-col justify-center px-16 w-full max-md:px-5 max-md:max-w-full max-w-screen-xl">
        <div className="justify-center px-8 py-5 mx-5 max-md:px-5 max-md:mr-2.5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <Link to='/'>
                <div className="flex flex-col w-30%] max-md:ml-0 max-md:w-full">
                <Logo src="/assets/logo.svg" alt="Company logo" />
                </div>
            </Link>
            <nav className="flex flex-col ml-5 w-[82%] max-md:ml-0 max-md:w-full">
              <div className="flex grow justify-center items-center self-stretch px-16 py-4 text-base font-semibold leading-6 text-white whitespace-nowrap max-md:px-5 max-md:mt-5 max-md:max-w-full">
                <div className="flex flex-wrap gap-5 justify-between items-center max-w-full w-[409px]">
                  <NavItem scrollTo ='/' title="Inicio" />
                  <NavDropdown scrollTo='/#services' label="Servicios" iconSrc="" iconAlt="Dropdown arrow icon" />
                  <NavItem scrollTo ='/#ours' title="Nosotros" />
                  <NavItem scrollTo="/#contact" title="Contactanos" />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>);
  }

  export default Header;
  