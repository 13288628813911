import * as React from "react";

interface ButtonProps {
  text: string;
}

const Button: React.FC<ButtonProps> = ({ text }) => {
  return (
    <div className="flex justify-center items-center px-16 py-7 mt-16 w-full text-base font-semibold leading-6 text-white bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col justify-center max-w-full w-[232px]">   
        <button  onClick={() => window.scrollTo(0,0)} className="justify-center px-5 py-3 rounded-lg border border-solid shadow-sm bg-stone-300 border-neutral-400">
          {text}
        </button>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <main>
      <Button text="Volver al inicio" />
    </main>
  );
}