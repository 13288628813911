import * as React from "react";

interface ServiceCardProps {
  title: string;
  description: React.JSX.Element;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description }) => (
  <div className="flex flex-col justify-center max-md:max-w-full">
    <h2 className="flex justify-start text-4xl font-semibold leading-6 text-neutral-500 max-md:max-w-full max-sm:text-3xl">{title}</h2>
    <div className="flex flex-col justify-center mt-10 text-lg leading-7 text-slate-600 max-md:max-w-full">
      <p className="text-justify justify-center max-md:max-w-full">{description}</p>
    </div>
  </div>
);

const Services: React.FC = () => {
  const services = [
    {
      title: "Servicios",
      description: (
        <>
          En Staffing Experience, nos destacamos por nuestras dos principales líneas de negocio:
          <p className="indent-5">&#x2022; En primer lugar, ofrecemos un acompañamiento integral en consultoría especializada en Automatización de Procesos (RPA) y BPM. Nuestro enfoque se centra en proporcionar soluciones innovadoras para optimizar los procesos empresariales, brindando así una mayor eficiencia y competitividad a tu empresa.</p> 
          <p className="indent-5">&#x2022; Por otro lado, nos especializamos en el servicio de Staff Augmentation, donde ofrecemos el talento necesario para llevar a cabo exitosamente los proyectos de nuestros clientes. Con una combinación única de experiencia en tecnología y recursos humanos, ofrecemos servicios personalizados que impulsan la eficiencia operativa y el crecimiento de tu negocio.</p>
          
        </>
      ),
    },
  ];

  return (
    <section className="flex justify-center items-center pt-14 pb-8 w-full bg-white max-md:px-5 max-md:max-w-full" id="services" >
      <div className="flex flex-col justify-center px-8 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full" >
        {services.map((service, index) => (
          <ServiceCard key={index} title={service.title} description={service.description} />
        ))}
        <img
          loading="lazy"
          src="/assets/consult-3.webp"
          alt="Services"
          style={{ objectPosition: '0% 65%', height: '30rem', width: '80rem'}}
          className="mt-16 w-full max-md:mt-10 max-md:max-w-full rounded-md object-cover"
        />
      </div>
    </section>
  );
};

export default Services;