"use client"
import * as React from "react";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Modal from "../Modal/Modal";


interface LogoProps {
  src: string;
  alt: string;
}

const Logo: React.FC<LogoProps> = ({ src, alt }) => (
  <div className="flex justify-center items-center self-stretch my-auto max-md:mt-7 overflow-hidden" onClick={()=> {
    const section = document.getElementById('ini');
    section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }}>
    <img loading="lazy" src={src} alt={alt} className="w-[300px]" />
  </div>
);

interface NavItemProps {
  title: string;
  scrollTo: string;
}

const NavItem: React.FC<NavItemProps> = ({ title, scrollTo }) => (
  <div className="justify-center self-stretch my-auto" role="button" onClick={()=> { 
    const section = document.getElementById(scrollTo);
    section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
   }}>{title}</div>
);

interface NavDropdownProps {
  label: string;
  iconSrc: string;
  iconAlt: string;
  scrollTo: string;
}

const NavDropdown: React.FC<NavDropdownProps> = ({ label, iconSrc, iconAlt,scrollTo }) => (
  <div className="flex flex-col justify-center self-stretch py-1" role="button" onClick={()=> {
    const section = document.getElementById(scrollTo);
    section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }}>
    <div className="flex gap-2 justify-center">
      <div>{label}</div>
      {/* <img loading="lazy" src={iconSrc} alt={iconAlt} className="shrink-0 my-auto w-5 aspect-square" /> */}
    </div>
  </div>
);

interface HeroProps {
  title: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
}

const sliderComponent = () => {
  const options = {
    type         : 'loop',
    gap          : '0.5rem',
    autoplay     : true,
    pauseOnHover : false,
    resetProgress: false,
    arrows       : false,
    spped        : 2000
  };
  const imagesCarrousel = [
    { src: '/assets/consult-1.webp', alt: `image1`},
    { src: '/assets/rpa_1.webp', alt: `image0`},
    { src: '/assets/staffing.webp', alt: `image0`},
    { src: '/assets/consult-2.webp', alt: `image2`},
    { src: '/assets/consult-4.webp', alt: `image3`},
  ]
  return (
    <div className="wrapper">
      <Splide
        options={ options }
        aria-labelledby="autoplay-example-heading"
        hasTrack={ false }
      >
        <div style={ { position: 'relative' } }>
          <SplideTrack>
            { imagesCarrousel.map( (slide, index) => (
              <SplideSlide key={ slide.src + index }>
                <img src={ slide.src } alt={ slide.alt } className="w-full max-md:mt-10 max-md:max-w-full object-cover rounded-md" 
        style={{ objectPosition: '0% 10%', height: '30rem', width: '80rem'}}/>
              </SplideSlide>
            ) ) }
          </SplideTrack>
        </div>
        <div className="splide__progress">
          <div className="splide__progress__bar" />
        </div>
      </Splide>
    </div>
  );
};

const Hero: React.FC<HeroProps> = ({ title, description, imageSrc, imageAlt }) => { 
 const [modal, showModal] = React.useState(false);

  return (
  <div className="flex flex-col justify-center px-8 max-md:px-5 max-md:max-w-full">
    <Modal modal={modal} onCloseModal={()=> showModal(false)}/>
    <div className="flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col w-full max-w-screen-lg max-md:max-w-full">
        <div className="flex flex-col font-medium max-md:max-w-full">
          <div className="flex gap-3 self-center py-1 pr-2.5 pl-1 text-sm leading-5 rounded-2xl mix-blend-multiply bg-neutral-500">
            <div className="justify-center px-2.5 py-0.5 text-center rounded-2xl bg-zinc-50 text-stone-700">
              Estamos buscandote!
            </div>
            <div className="flex gap-1 my-auto text-white" role='button' onClick={()=> showModal(true)}>
              <div>Unete a nuestro equipo</div>
              <img loading="lazy" src="/assets/arrow.svg" alt="" className="shrink-0 my-auto w-4 aspect-square" />
            </div>
          </div>
          <h1 className="mt-4 text-6xl max-sm:text-4xl tracking-tighter text-center text-gray-900 leading-[72px] max-md:max-w-full max-md:text-4xl max-md:leading-[55px]">
            {title}
          </h1>
        </div>
        <p className="self-center mt-6 text-xl leading-8 text-center text-slate-600 w-[651px] max-md:max-w-full">
          {description}
        </p>
      </div>
    </div>
    <div className="flex flex-col justify-center px-8 mt-6 max-md:px-5 max-md:mt-10 max-md:max-w-full self-center w-full  max-w-screen-xl items-center max-sm:hidden overflow-hidden">
      <div className="flex flex-column justify-center max-md:max-w-full">
        <div>
          {sliderComponent()}
        </div>
      </div>
    </div>
  </div>
);
}

function MyComponent() {

  return (
    <div className="flex flex-col w-full bg-white max-md:max-w-full" id="init">
      <header className="flex flex-col justify-center w-full border-solid bg-stone-900 border-b-[13px] border-neutral-500 max-md:max-w-full items-center">
        <div className="flex flex-col justify-center px-16 w-full max-md:px-5 max-md:max-w-full max-w-screen-xl">
          <div className="justify-center px-8 py-5 mx-5 max-md:px-5 max-md:mr-2.5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-30%] max-md:ml-0 max-md:w-full">
                <Logo src="/assets/logo.svg" alt="Company logo" />
              </div>
              <nav className="flex flex-col ml-5 w-[82%] max-md:ml-0 max-md:w-full">
                <div className="flex grow justify-center items-center self-stretch px-16 py-4 text-base font-semibold leading-6 text-white whitespace-nowrap max-md:px-5 max-md:mt-5 max-md:max-w-full">
                  <div className="flex flex-wrap gap-5 justify-between items-center max-w-full w-[409px]">
                    <NavDropdown scrollTo='services' label="Servicios" iconSrc="" iconAlt="Dropdown arrow icon" />
                    <NavItem scrollTo ='ours' title="Nosotros" />
                    <NavItem scrollTo="contact" title="Contactanos" />
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <main className="flex flex-col px-20 py-20 w-full max-md:px-5 max-md:max-w-full">
        <Hero
          title="Queremos convertirnos en el principal referente del sector de staffing"
          description="Reconocidos por nuestra excelencia en servicio, innovación constante, y por la creación de relaciones sólidas y duraderas con nuestros clientes y candidatos"
          imageSrc="/assets/consult-1.webp"
          imageAlt="Hero image"
        />
      </main>
    </div>
  );
}

export default MyComponent;