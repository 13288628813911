import * as React from "react";

interface StaffAugmentationProps {
  title: string;
  description: string;
  imageUrl: string;
  benefits: string[];
}

function StaffAugmentation({ title, description, imageUrl, benefits }: StaffAugmentationProps) {
  return (
    <section className="flex justify-center items-center px-16 pt-20 pb-20 w-full bg-white rounded max-md:px-5 max-md:max-w-full">
      <div className="justify-center px-8 mt-4 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow justify-center max-md:mt-10 max-md:max-w-full">
              <img loading="lazy" src={imageUrl} alt="Staff Augmentation" style={{ objectPosition: '0% 65%', height: '30rem', width: '80rem'}} className="mt-4 w-full max-md:mt-10 max-md:max-w-full rounded-md object-cover" />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col mt-2 max-md:mt-10 max-md:max-w-full">
              <h2 className="flex justify-start text-4xl font-semibold tracking-tighter leading-10 text-gray-900 max-md:max-w-full">
                {title}
              </h2>
              <div className="flex flex-col justify-center mt-10 text-lg leading-7 text-slate-600 max-md:max-w-full">
                <p className="text-justify pb-5 max-md:max-w-full">
                  {description}
                  <br />
                  <br />
                  Nuestro enfoque se basa en:
                  <br />
                  {benefits.map((benefit, index) => (
                    <React.Fragment key={index}>
                      <p className="indent-5">&#x2022;{" " + benefit}</p>
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function MyComponent() {
  const staffAugmentationData: StaffAugmentationProps = {
    title: "Staff Augmentation",
    description: "¿Necesitas encontrar al candidato perfecto para tu equipo? Deja que nosotros te ayudemos. Con nuestro servicio de staffing, tendrás acceso a un vasto pool de talento calificado que se adapta a tus necesidades específicas. Olvídate de las largas búsquedas y los procesos de contratación engorrosos. Nosotros nos encargamos de todo para que puedas enfocarte en lo que realmente importa: hacer crecer tu negocio.",
    imageUrl: "/assets/staffing.webp",
    benefits: [
      "Disponibilizar talento calificado para tus proyectos.",
      "Cubrir tus posiciones vacantes de manera rápida y eficiente.",
      "Garantizar la adaptabilidad a tus necesidades de recursos humanos.",
      "Reducir costos asociados a la contratación.",
      "Enfocar tus objetivos comerciales principales."
    ]
  };

  return <StaffAugmentation {...staffAugmentationData} />;
}